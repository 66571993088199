.c-product-grid-item {
  width: calc(25% - 1.2rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white-primary;
  border-radius: 0.8rem;

  @media screen and (max-width: 2000px) {
    width: calc(100% / 3 - 1.2rem);
  }

  @media screen and (max-width: 2000px) {
    width: calc(100% / 3 - 1.2rem);
  }

  @media screen and (max-width: 1023px) {
    width: calc(50% - 1.2rem);
    border: 1px solid $grey-primary;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }

  &.is-active {
    border: 2px solid $red-primary;
  }

  &__top {
    width: 100%;
    height: 20rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    border-bottom: 1px solid $grey-primary;
    cursor: pointer;

    .checkbox {
      appearance: none;
      background-color: $white-primary;
      border: 1px solid #c1c1c1;
      border-radius: 3px;
      display: grid;
      height: 2rem;
      margin: 0;
      place-content: center;
      width: 2rem;
      min-width: 2rem;
      min-height: 2rem;

      &:checked:before {
        transform: scale(1);
      }

      &:before {
        box-shadow: inset 1em 1em #0b090a;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
        content: "";
        height: 0.65em;
        transform: scale(0);
        transform-origin: bottom left;
        transition: transform 0.3s ease-in-out;
        width: 0.65em;
      }
    }

    .thumbnail {
      width: auto;
      height: 100%;

      img {
        width: auto;
        height: 100%;
        object-fit: contain;
      }
    }

    .favourites {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    height: calc(100% - 20rem);

    &__title {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      &__old-model {
        span {
          font-size: 1.3rem;
        }
      }

      &__color {
        display: flex;
        align-items: center;

        .color {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          margin-right: 0.5rem;
        }

        span {
          font-size: 1.3rem;
        }
      }

      h2 {
        margin-bottom: 0.2rem;
        font-size: 1.8rem;
      }
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__specs {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        &__spec {
          display: flex;
          align-items: flex-start;
          font-size: 1.3rem;
          margin-bottom: 0.5rem;

          .label {
            width: 50%;
          }

          .value {
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &__link {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .c-btn {
          padding: 1rem 2rem;

          span {
            font-weight: normal;
          }
        }

        &__price {
          font-size: 1.6rem;
        }
      }
    }
  }
}
